import { useEffect, useState } from 'react';

import { aggregationsAtom, initialAggregationsState } from '@core/Atoms/Search/AggregationsAtom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Grid from '@gds/Grid/Grid';
import { makeAtom } from '@search/Atoms/MakeAtom';
import { useAtomValue } from 'jotai';

import { MakeObject } from 'Components/SearchWidget/Search.entity';
import { searchWidgetMessages } from 'Components/SearchWidget/SearchWidget.messages';
import { useInitAggregations } from 'Hooks/UseInitAggregations';

import styles from '../../Search.module.css';
import Make from '../Make/Make';
import Model from '../Model/Model';

type MakeModelWidgetProps = {
  locale: Locale;
  strings: Record<keyof typeof searchWidgetMessages, string>;
  tabletItemGridSpan: number;
  desktopItemGridSpan: number;
  isReviewsPage?: boolean;
  reviewItems?: MakeObject[];
};

export const MakeModelWidget = ({
  locale,
  strings,
  tabletItemGridSpan,
  desktopItemGridSpan,
  reviewItems,
  isReviewsPage,
}: MakeModelWidgetProps) => {
  useInitAggregations(locale);

  const makesModelsCount = useAtomValue(aggregationsAtom);
  const selectedMake = useAtomValue(makeAtom);

  const [makesModelsCountInternal, setMakesModelsCountInternal] =
    useState(initialAggregationsState);
  const selectedMakeId = selectedMake.make;

  const selectedModelAggs =
    selectedMakeId && makesModelsCount.makes
      ? makesModelsCount.makes.find(make => make.key === selectedMakeId)
      : undefined;

  useEffect(() => {
    setMakesModelsCountInternal(makesModelsCount);
  }, [makesModelsCount]);

  return (
    <>
      <Grid.Col
        className={styles.searchCol}
        width={{ mobile: 12, tablet: tabletItemGridSpan, desktop: desktopItemGridSpan }}
      >
        <Make
          makePlaceholder={strings.makePlaceholder}
          modelPlaceholder={strings.modelPlaceholder}
          searchPlaceholder={strings.searchPlaceholder}
          noResultText={strings.noResultText}
          makeTitle={strings.makeTitle}
          state={makesModelsCountInternal}
          updateFn={setMakesModelsCountInternal}
          defaultCount={makesModelsCount.totalCount}
          isReviewsPage={isReviewsPage}
          reviewItems={reviewItems}
        />
      </Grid.Col>
      <Grid.Col
        className={styles.searchCol}
        width={{ mobile: 12, tablet: tabletItemGridSpan, desktop: desktopItemGridSpan }}
      >
        <Model
          modelPlaceholder={strings.modelPlaceholder}
          searchPlaceholder={strings.searchPlaceholder}
          noResultText={strings.noResultText}
          modelTitle={strings.modelTitle}
          state={makesModelsCountInternal}
          updateFn={setMakesModelsCountInternal}
          defaultCount={selectedModelAggs?.count ?? 0}
          isReviewsPage={isReviewsPage}
          reviewItems={reviewItems}
        />
      </Grid.Col>
    </>
  );
};
